.homeitem

    @media (prefers-reduced-motion: no-preference)
        animation: homeitem__animation--in 0.8s cubic-bezier(0.22, 1, 0.36, 1) both

    &__number

        @media (prefers-reduced-motion: no-preference)
            animation: homeitem__number__animation--in 2s ease-out both

    &__progress
        @apply origin-top

        @media (prefers-reduced-motion: no-preference) and (min-width: 768px)
            animation: homeitem__progress__animation--in 1s 0.25s cubic-bezier(0.22, 1, 0.36, 1) both

    &__extra
        @apply md:origin-left

        @media (prefers-reduced-motion: no-preference) and (min-width: 768px)
            animation: homeitem__extra__animation--in 0.8s cubic-bezier(0.22, 1, 0.36, 1)

@keyframes homeitem__animation--in
    0%
        opacity: 0
        transform: translateY(20%)
    20%
        opacity: 0
    100%
        opacity: 1
        transform: translateY(0)

@keyframes homeitem__number__animation--in
    0%
        opacity: 0
    100%
        opacity: 1

@keyframes homeitem__progress__animation--in
    0%
        opacity: 0
        transform: translateY(-100%)
    100%
        opacity: 1
        transform: translateY(0)

@keyframes homeitem__extra__animation--in
    0%
        opacity: 0
        transform: scale(0.5)
    100%
        opacity: 1
        transform: scale(1)